<template>
  <v-app style="background-color: #f5f4f6">
    <v-app-bar app>
      <a href="https://paradigmshift.io">
        <v-img
          src="@/assets/logo.png"
          contain
          max-width="90"
          alt="logo paradigmshift inc."
        ></v-img>
      </a>
      <v-spacer></v-spacer>
      <div style="width: 120px">
        <v-select
          v-model="lang"
          :items="langs"
          outlined
          dense
          hide-details
          label="language"
        ></v-select>
      </div>
    </v-app-bar>
    <v-main>
      <router-view />
      <v-divider></v-divider>
      <footer class="py-4">
        <div class="text-center">
          <small>
            Copyright &copy Paradigmshift Inc All Rights Reserved.
          </small>
        </div>
      </footer>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: 'App',

    computed: {
      lang: {
        get() {
          return this.$route.params.lang
        },
        set(val) {
          this.$router.push({
            params: {
              lang: val,
            },
          })
        },
      },
      langs() {
        return [
          { text: '日本語', value: 'ja' },
          { text: 'English', value: 'en' },
        ]
      },
    },
  }
</script>

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_mobile_analytics_app_id": "c7dea7091e7a4e55933c652cdda2f6e3",
    "aws_mobile_analytics_app_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://pxn3rftwoveyxmv6cvo5yrlvpa.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-sqzylojlwrgp7isfcivxq3ax7y",
    "aws_cloud_logic_custom": [
        {
            "name": "RcInternalApi",
            "endpoint": "https://328zz1cn5m.execute-api.ap-northeast-1.amazonaws.com/master",
            "region": "ap-northeast-1"
        },
        {
            "name": "RcUserApi",
            "endpoint": "https://dkxyyf9kv1.execute-api.ap-northeast-1.amazonaws.com/master",
            "region": "ap-northeast-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-northeast-1:7832bcf5-2192-4a8f-8d1b-c8426d7499f8",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_BJM9wb3WY",
    "aws_user_pools_web_client_id": "4gh2mcse5mac9g29buludghbd7",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "6",
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "rc3-amplify-storage141702-master",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,

  mounted() {
    this.$i18n.locale = this.lang
  },
  watch: {
    lang(val) {
      this.$i18n.locale = val
    },
  },
  computed: {
    lang() {
      return this.$route.params.lang
    },
  },

  render: (h) => h(App),
}).$mount('#app')

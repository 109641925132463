<i18n>
{
  "en": {
    "required": "Required",
    "requiredMessage": "This field is required.",
    "notice": "Please fill in the required information from the form below and press the \"Confirm input contents\" button.The person in charge will reply depending on the content of your inquiry.",
    "organization": "Organization",
    "organizationLabel": "Your hotel or facility name",
    "name": "Name",
    "familyName": "Family name",
    "givenName": "Given name",
    "nameKana": "Name",
    "familyNameKana": "Family name",
    "givenNameKana": "Given name",
    "email": "Email",
    "emailMessage": "This address is not proper shape.",
    "tel": "Phone",
    "cat": "Inquiry content selection",
    "pleaseSelect": "Please select",
    "body": "Comments",
    "bodyPlaceholder": "Please enter your inquiry as specifically as possible in the fields below.",
    "reset": "Reset",
    "check": "Confirmation of inquiries",
    "cancel": "Cancel",
    "submit": "Send the above contents",
    "complete": {
      "title": "Thank you for contacting us.",
      "subTitle": "We have received your inquiry.",
      "message": "The staff in charge will check the contents and will contact the person in charge.\nBasically, we will reply within 24 hours (excluding weekends and holidays), but depending on the content of the inquiry, it may take 2-3 days to reply.\nWe apologize for the inconvenience, but we would appreciate it if you could wait for a while until you answer. \nIn addition, we have sent a confirmation email to the address you entered in the inquiry form. We apologize for the inconvenience, but please check it.\nIf you have any questions, please feel free to contact us."
    }
  },
  "ja": {
    "required": "必須",
    "requiredMessage": "必須項目です",
    "notice": "下記のフォームより必要事項を入力し、「入力内容確認」ボタンを押してください。\nお問合せの内容により担当者よりご返信させていただきます。",
    "organization": "貴社名",
    "organizationLabel": "ホテル・旅館名",
    "name": "ご担当者氏名",
    "familyName": "姓",
    "givenName": "名",
    "nameKana": "ご担当者氏名（カナ）",
    "familyNameKana": "セイ",
    "givenNameKana": "メイ",
    "email": "連絡先メールアドレス",
    "emailMessage": "",
    "tel": "連絡先電話番号",
    "cat": "お問合せ種別",
    "pleaseSelect": "お問合せ種別を選択して下さい",
    "body": "お問合せ内容",
    "bodyPlaceholder": "お問合せ内容を下記記入欄へなるべく具体的にご入力ください。",
    "reset": "リセット",
    "check": "お問合せ内容のご確認",
    "cancel": "キャンセル",
    "submit": "上記内容を送信",
    "complete": {
      "title": "お問合せいただき、ありがとうございました。",
      "subTitle": "問い合わせを受付いたしました。",
      "message": "担当スタッフが内容を確認し、折り返しご担当者様にご連絡いたします。\n基本的には24時間以内（土日祝日を除く）にご返答させていただいておりますが、お問合せの内容によっては、回答に2、3日お時間をいただく場合もございます。\nお待たせして大変恐縮ではございますが、ご回答まで今しばらくおまちくださいますようお願い申し上げます。\nまた、お問合せフォームよりご入力いただきましたアドレス宛にご確認のメールを送信させていただきました。お手数ではございますが、ご確認いただけますようお願い致します。\nその他、何かご不明な点等ございましたら、お気軽にお問合せ下さいませ。"
    }
  }
}
</i18n>

<template>
  <section class="mb-16">
    <v-container style="max-width: 820px">
      <h1 class="text-center my-10">
        <span class="text-h3 font-weight-bold">Contact</span>
        <span class="text-h6 ml-sm-4 d-block d-sm-inline">- お問合せ -</span>
      </h1>
      <template v-if="!isSent">
        <p>
          {{ $t('notice') }}
        </p>
        <v-divider class="my-8"></v-divider>
        <!-- 入力パネル -->
        <section>
          <v-form ref="form" @submit.prevent="check">
            <section>
              <v-subheader>
                {{ $t('organization') }}
                <v-chip x-small outlined color="error" class="ml-2">{{
                  $t('required')
                }}</v-chip>
              </v-subheader>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="request.facility_name"
                    dense
                    outlined
                    :label="$t('organizationLabel')"
                    name="organization"
                    :rules="[(v) => !!v || $t('requiredMessage')]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </section>
            <section>
              <v-subheader>
                {{ $t('name') }}
                <v-chip x-small outlined color="error" class="ml-2">{{
                  $t('required')
                }}</v-chip>
              </v-subheader>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="request.last_name"
                    dense
                    outlined
                    :label="$t('familyName')"
                    name="family-name"
                    :rules="[(v) => !!v || $t('requiredMessage')]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="request.first_name"
                    dense
                    outlined
                    :label="$t('givenName')"
                    name="given-name"
                    :rules="[(v) => !!v || $t('requiredMessage')]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </section>
            <section>
              <v-subheader>
                {{ $t('nameKana') }}
                <v-chip x-small outlined color="error" class="ml-2">{{
                  $t('required')
                }}</v-chip>
              </v-subheader>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="request.last_name_kana"
                    dense
                    outlined
                    :label="$t('familyNameKana')"
                    name="family-name"
                    :rules="[(v) => !!v || $t('requiredMessage')]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="request.first_name_kana"
                    dense
                    outlined
                    :label="$t('givenNameKana')"
                    name="given-name"
                    :rules="[(v) => !!v || $t('requiredMessage')]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </section>
            <section>
              <v-subheader>
                {{ $t('email') }}
                <v-chip x-small outlined color="error" class="ml-2">{{
                  $t('required')
                }}</v-chip>
              </v-subheader>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="request.replying_email"
                    dense
                    outlined
                    type="email"
                    name="email"
                    :rules="[
                      (v) => !!v || $t('requiredMessage'),
                      (v) => /.+@.+\..+/.test(v) || $t('emailMessage'),
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </section>
            <section>
              <v-subheader>
                {{ $t('tel') }}
                <v-chip x-small outlined color="error" class="ml-2">{{
                  $t('required')
                }}</v-chip>
              </v-subheader>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="request.replying_tel"
                    dense
                    outlined
                    name="tel"
                    :rules="[(v) => !!v || $t('requiredMessage')]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </section>
            <section>
              <v-subheader>
                {{ $t('cat') }}
                <v-chip x-small outlined color="error" class="ml-2">{{
                  $t('required')
                }}</v-chip>
              </v-subheader>
              <v-row>
                <v-col>
                  <v-select
                    v-model="request.inquiry_category_id"
                    dense
                    outlined
                    :items="cats"
                    :label="$t('pleaseSelect')"
                    :rules="[(v) => !!v || $t('requiredMessage')]"
                  ></v-select>
                </v-col>
              </v-row>
            </section>
            <section>
              <v-subheader>
                {{ $t('body') }}
                <v-chip x-small outlined color="error" class="ml-2">{{
                  $t('required')
                }}</v-chip>
              </v-subheader>
              <v-row>
                <v-col>
                  <v-textarea
                    v-model="request.inquiry_text"
                    outlined
                    :placeholder="$t('bodyPlaceholder')"
                    :rules="[(v) => !!v || $t('requiredMessage')]"
                  ></v-textarea>
                </v-col>
              </v-row>
            </section>
            <footer>
              <v-row>
                <v-col class="d-sm-none text-center">
                  <v-btn
                    type="submit"
                    block
                    large
                    rounded
                    color="secondary"
                    class=""
                    >{{ $t('check') }}</v-btn
                  >
                  <v-btn
                    @click="reset"
                    block
                    large
                    rounded
                    color="white"
                    class="mt-6"
                    >{{ $t('reset') }}</v-btn
                  >
                </v-col>
                <v-col class="d-none d-sm-block text-center">
                  <v-btn @click="reset" large rounded color="white">{{
                    $t('reset')
                  }}</v-btn>
                  <v-btn
                    type="submit"
                    large
                    rounded
                    color="secondary"
                    class="px-10 ml-6"
                    >{{ $t('check') }}</v-btn
                  >
                </v-col>
              </v-row>
            </footer>
          </v-form>
        </section>
        <!-- 確認パネル -->
        <v-dialog
          v-model="isShowCheckPanel"
          scrollable
          transition="dialog-bottom-transition"
          max-width="600"
        >
          <template v-slot:default="dialog">
            <v-card>
              <v-card-title> {{ $t('check') }} </v-card-title>
              <v-divider></v-divider>
              <v-form @submit.prevent="submit">
                <v-card-text height="80vh">
                  <v-text-field
                    readonly
                    hide-details
                    :label="$t('organization')"
                    :value="request.facility_name"
                    class="mt-4"
                  ></v-text-field>
                  <v-text-field
                    readonly
                    hide-details
                    :label="$t('name')"
                    :value="`${request.last_name} ${request.first_name} ( ${request.last_name_kana} ${request.first_name_kana} ) 様`"
                    class="mt-4"
                  ></v-text-field>
                  <v-text-field
                    readonly
                    hide-details
                    :label="$t('email')"
                    :value="request.replying_email"
                    class="mt-4"
                  ></v-text-field>
                  <v-text-field
                    readonly
                    hide-details
                    :label="$t('tel')"
                    :value="request.replying_tel"
                    class="mt-4"
                  ></v-text-field>
                  <v-text-field
                    readonly
                    hide-details
                    :label="$t('cat')"
                    :value="contactCategory.label[lang]"
                    class="mt-4"
                  ></v-text-field>
                  <v-textarea
                    readonly
                    hide-details
                    :label="$t('body')"
                    :value="request.inquiry_text"
                    class="mt-4"
                  ></v-textarea>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="d-block py-6">
                  <div>
                    <div class="d-sm-none text-center">
                      <v-btn
                        type="submit"
                        block
                        large
                        rounded
                        color="secondary"
                        class=""
                        >{{ $t('submit') }}</v-btn
                      >
                      <v-btn
                        @click.stop="isShowCheckPanel = false"
                        block
                        large
                        rounded
                        color="white"
                        class="mt-6"
                        >{{ $t('cancel') }}</v-btn
                      >
                    </div>
                    <div class="d-none d-sm-block text-center">
                      <v-btn
                        @click.stop="isShowCheckPanel = false"
                        large
                        rounded
                        color="white"
                        >{{ $t('cancel') }}</v-btn
                      >
                      <v-btn
                        type="submit"
                        large
                        rounded
                        color="secondary"
                        class="px-10 ml-6"
                        >{{ $t('submit') }}</v-btn
                      >
                    </div>
                  </div>
                </v-card-actions>
              </v-form>
            </v-card>
          </template>
        </v-dialog>
      </template>
      <template v-else>
        <!-- 完了パネル -->
        <v-card outlined>
          <v-card-title> {{ $t('complete.title') }}<br /> </v-card-title>
          <v-card-subtitle> {{ $t('complete.subTitle') }} </v-card-subtitle>
          <v-card-text>
            {{ $t('complete.message') }}
          </v-card-text>
        </v-card>
      </template>
    </v-container>
  </section>
</template>

<script>
  import {API} from 'aws-amplify';
  export default {
    data: () => ({
      isSent: false,
      isShowCheckPanel: false,
      request: {
        facility_name: null,
        last_name: null,
        last_name_kana: null,
        first_name: null,
        first_name_kana: null,
        replying_email: null,
        replying_tel: null,
        inquiry_category_id: null,
        inquiry_text: null,
      },
    }),
    computed: {
      lang() {
        return this.$route.params.lang
      },
      cats() {
        const lang = this.lang
        return this.contactCategories.map((o) => ({
          text: o.label[lang],
          value: o.id,
        }))
      },
      contactCategory() {
        return (
          this.contactCategories.find(
            (o) => o.id == this.request.inquiry_category_id
          ) || {}
        )
      },
      contactCategories() {
        return [
          {
            id: 23,
            service: 'Psinc',
            service_name: '',
            name: '弊社に関するお問い合わせ',
            label: {
              en: 'Inquiries about our company',
              ja: '弊社に関するお問い合わせ',
            },
            updated_at: '2020-09-16 18:34:50',
            created_at: '2020-09-16 18:34:50',
          },
          {
            id: 24,
            service: 'Psinc',
            service_name: '',
            name: 'プレス・メディア関連のお問い合わせ',
            label: {
              en: 'Inquiries about press and media',
              ja: 'プレス・メディア関連のお問い合わせ',
            },
            updated_at: '2020-09-16 18:34:50',
            created_at: '2020-09-16 18:34:50',
          },
          {
            id: 25,
            service: 'Psinc',
            service_name: '',
            name: '弊社サービスに関するお問い合わせ',
            label: {
              en: 'Inquiries about our services',
              ja: '弊社サービスに関するお問い合わせ',
            },
            updated_at: '2020-09-16 18:34:50',
            created_at: '2020-09-16 18:34:50',
          },
          {
            id: 27,
            service: 'Psinc',
            service_name: '',
            name: 'RC Bookingについて',
            label: {
              en: 'Abount RC Booking',
              ja: 'RC Bookingについて',
            },
            updated_at: '2021-02-17 17:38:00',
            created_at: '2021-02-17 17:38:00',
          },
          {
            id: 28,
            service: 'Psinc',
            service_name: '',
            name: 'RepCheckerについて',
            label: {
              en: 'Abount RepChecker',
              ja: 'RepCheckerについて',
            },
            updated_at: '2021-03-31 17:38:00',
            created_at: '2021-03-31 17:38:00',
          },
          {
            id: 29,
            service: 'Psinc',
            service_name: '',
            name: 'AiFaceについて',
            label: {
              en: 'Abount AiFace',
              ja: 'AiFaceについて',
            },
            updated_at: '2021-03-31 17:38:00',
            created_at: '2021-03-31 17:38:00',
          },
          {
            id: 30,
            service: 'Psinc',
            service_name: '',
            name: 'その他',
            label: {
              en: 'Other',
              ja: 'その他',
            },
            updated_at: '2020-09-16 18:34:50',
            created_at: '2020-09-16 18:34:50',
          },
        ]
      },
    },
    methods: {
      reset() {
        this.$refs.form.reset()
      },
      check() {
        if (this.$refs.form.validate()) {
          this.isShowCheckPanel = true
        }
      },
      async submit() {
        const request = {
          ...this.request,
          datas: {
            from: location.origin,
            files: [],
          },
        }

        try {
          // データ送信
          await API.post('RcUserApi', '/mail/contacts', {
            body: request,
          });
          // post send
          this.isSent = true
          this.isShowCheckPanel = false
          this.reset()
        } catch (e) {
          console.error(e)
          alert('送信に失敗しました。')
        }
      },
    },
  }
</script>
